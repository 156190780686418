import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userLoggedIn, userLoggedOut } from "../features/authSlice";
import createAxiosInstance from "../utlis/axios";

function RequireAuth({ children }) {
  const goLogin = useNavigate();
  const { REACT_APP_SESSION_TIMEOUT_MINUTES, REACT_APP_EXPRESS_BACKEND_URL } =
    process.env;
  const dispatch = useDispatch();
  let location = useLocation();
  const { accessToken, user, timestamp } = useSelector((state) => state.auth);
  const axiosInstance = createAxiosInstance()

  moment.fn.fromNow = function (a) {
    var duration = moment().diff(this, "minutes");
    return duration;
  };
  if (accessToken && user) {
    axiosInstance.get(`/authroute/verifyjwt`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    })
    .then((res) => {
      const storedToken = localStorage.getItem("accessToken");
      const auth = JSON.parse(storedToken);
      if (auth.accessToken == accessToken) return children;
      if (res?.data) {
        dispatch(userLoggedIn({
          accessToken: auth.accessToken,
          refreshToken: auth.refreshToken,
          user: auth.user,
          timestamp: auth.timestamp,
        }));
        return children;
      } else {
        dispatch(userLoggedOut());
        localStorage.removeItem("accessToken");
        return goLogin("/login", {
          state: { from: location },
          replace: true,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        dispatch(userLoggedOut());
        localStorage.removeItem("accessToken");
        return goLogin("/login", {
          state: { from: location },
          replace: true,
        });
      }
    });
    // Session-Timeout-Überprüfung
    // if (moment(timestamp).fromNow() >= REACT_APP_SESSION_TIMEOUT_MINUTES) {
    //   dispatch(userLoggedOut());
    //   localStorage.removeItem("accessToken");
    //   toast.warn(`User Session expired`, {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return <Navigate to="/login" state={{ from: location }} replace />;
    // }
  
    return children;
  } else {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      const auth = JSON.parse(storedToken);
  
      axiosInstance.get(`/authroute/verifyjwt`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        const storedToken = localStorage.getItem("accessToken");
        const auth = JSON.parse(storedToken);
        if (res?.data) {
          // console.log('data')
          // console.log(res.data)
          dispatch(userLoggedIn({
            accessToken: auth.accessToken,
            refreshToken: auth.refreshToken,
            user: auth.user,
            timestamp: auth.timestamp,
          }));
          return children;
        } else {
          dispatch(userLoggedOut());
          localStorage.removeItem("accessToken");
          return goLogin("/login", {
            state: { from: location },
            replace: true,
          });
        }
      })
      .catch((error) => {
        console.log('error')
        console.log(error.response)
        if (error.response && error.response.status === 401) {
          dispatch(userLoggedOut());
          localStorage.removeItem("accessToken");
          return goLogin("/login", {
            state: { from: location },
            replace: true,
          });
        }
      });
    } else {
      dispatch(userLoggedOut());
      localStorage.removeItem("accessToken");
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
}

export default RequireAuth;
