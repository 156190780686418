import axios from "axios";
import createAxiosInstance from "../../utlis/axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditFTPModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const { editFtp } = useSelector((state) => state.company);
  const [serverName, setName] = useState("");
  const [serverAddress, setAddress] = useState("");
  const [serverProtocol, setProtocol] = useState("FTP");
  const [serverCredentialsUsername, setCredentialsUsername] = useState("");
  const [serverCredentialsPassword, setCredentialsPassword] = useState("");
  // const [serverCredentials, setCredentials] = useState({});
  const [serverEnabled, setEnable] = useState(true);
  const [serverRoot, setRoot] = useState("/");
  const axiosInstance = createAxiosInstance()

  useEffect(() => {
    if (editFtp._id) {
      setName(editFtp.serverName);
      setAddress(editFtp.serverAddress);
      setProtocol(editFtp.serverProtocol);
      setCredentialsUsername(editFtp.serverCredentials.userName);
      setCredentialsPassword(editFtp.serverCredentials.userPassword);
      setEnable(editFtp.serverEnabled);
      setRoot(editFtp.serverRoot);
    }
  }, [editFtp]);

  const HandleEditFTP = async (e) => {
    e.preventDefault();
    const ftpInfo = { serverName, serverAddress, serverProtocol, serverCredentials: {userName: serverCredentialsUsername, userPassword: serverCredentialsPassword}, serverEnabled, serverRoot };

    try {
      await axiosInstance
        .put(`/deliveryRoute/ftp/${editFtp._id}`, ftpInfo)
        .then((res) => {
          if (res.data.success) {
            toast.success(`FTP update successfully`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
            setName("");
            setAddress("");
            setProtocol("");
            setCredentialsUsername("");
            setCredentialsPassword("");
            setEnable(false);
            setRoot("/");
            setModalShow();
            setRefetch(!refetch);
          }
          if (res.data.code === 11000) {
            toast.error("FTP already exists", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update FTP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleEditFTP}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Control
              type="text"
              value={serverName}
              placeholder="Server Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicAddress">
            <Form.Control
              type="text"
              value={serverAddress}
              placeholder="Server Domain or IP"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicProtocol">
            <Form.Control
              type="text"
              value={serverProtocol}
              placeholder="FTP"
              readOnly
              onChange={(e) => setProtocol(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicUsername">
            <Form.Control
              required
              type="text"
              value={serverCredentialsUsername}
              placeholder="FTP User name"
              onChange={(e) => setCredentialsUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="password"
              value={serverCredentialsPassword}
              placeholder="FTP Password"
              onChange={(e) => setCredentialsPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicRoot">
            <Form.Control
              type="text"
              value={serverRoot}
              placeholder="Root directory, default is /"
              onChange={(e) => setRoot(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEnabled">
            <Form.Check
              type="checkbox"
              label="Allow FTP Upload"
              defaultChecked={serverEnabled}
              onChange={(e) => setEnable(e.target.checked)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Update company
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditFTPModal;
