import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLoggedOut } from "../features/authSlice";

let axiosInstance = null;

const CreateAxiosInstance = () => {
  const token = JSON.parse(localStorage.getItem("accessToken"));
  const dispatch = useDispatch();
  const navigateToHome = useNavigate()

  axiosInstance = axios.create();

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token?.accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const response = await axios.get('/authroute/token', {
            headers: {
              Authorization: `Bearer ${token?.refreshToken}` // Add Bearer token to the Authorization header
            },
            withCredentials: true,
          });
          const { access_token } = response.data;
          const { refresh_token } = response.data;
          localStorage.setItem('accessToken', JSON.stringify({...token, accessToken: access_token, refreshToken: refresh_token}));

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          return axios(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
          console.log('error')
          console.log(error)
          dispatch(userLoggedOut());
          localStorage.removeItem("accessToken");
          return navigateToHome('/login')
          
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default CreateAxiosInstance;